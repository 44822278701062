import React from 'react';

import Footer from './components/Footer';
import Home from './components/pages/Home';


import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './normalize.min.css';
import './App.css';

import 'lazysizes';
//import './LazyVideoPlayer.js';
import './BackgroundPainter.js';


function App() {
  return (
    
    <div className="app">
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
      </Routes>
      <Footer />
    </Router>
    </div>
  );
}

export default App;