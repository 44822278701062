import React from 'react';

// import {Link} from 'react-router-dom';
import ContentRows from '../ContentRows';

import './Home.css';

// import logo from '../../img/logo.svg'; 

const rowsData = [
    {
      title:<>
      Hi, I'm Evan.
      </>,
      details: <>
      While most of my professional work is Shopify and E-commerce for private clients. This site is more a showcase of my passion projects as a creative developer. I specialize in ecomerce, front-end engineering, and interactive motion graphics. My guideposts are simplicity, honesty, and natural feel. 
      <br/>
      <br/>Evan Glover
      <br/>New York, NY
      <br/><span><a href="mailto:evan@evanmglover.com">
        evan@evanmglover.com
      </a></span>
      </>,
      img_large:"https://res.cloudinary.com/dvcpob8i3/image/upload/v1659408394/EVANMGLOVERCOM/XXX_5995_b7vcg9.jpg",
      img_small:"https://res.cloudinary.com/dvcpob8i3/image/upload/c_scale,w_600/v1659408394/EVANMGLOVERCOM/XXX_5995_b7vcg9.jpg",
      img_alt:"Evan Glover"
    },
    {
      title:<>
      <a href="https://wearbigwords.com/" target='_blank' rel="noreferrer">Wear Big Words</a>
      </>,
      details: <>
      Big words, big thoughts. A next-generation brutalist clothing brand with handmade custom theme by yours truly.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668754630/EVANMGLOVERCOM/Shirts_Mugs_Fashion._A_place_for_thoughts._Wear_Big_Words._wearbigwords_-_Google_Chrome_2022-08-01_23-41-42_Trim_COMP_iwfeqr.mp4",
      img:"",
      img_alt:"Wear Big Words Demo"
    },
    {
      title:<>
      <a href="https://designerfeed.me/" target='_blank' rel="noreferrer">Designerfeed</a>
      </>,
      details: <>
      A constantly updating collection of the newest projects from a hand-picked set of elite designers. In collaboration with <a href="https://hamayunau.com/">Dima Hamayunau</a> and Alexander Hamayunau.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668754691/EVANMGLOVERCOM/Designerfeed_automatically_collects_the_newest_projects_from_trusted_design_sources._-_Google_Chrome_2022-08-01_23-36-59_Trim_COMP_qdpbit.mp4",
      img:"",
      img_alt:"Designerfeed Demo"
    },
    {
      title:<>
      <a href="https://www.youtube.com/channel/UCl6r5ZKt87gnUL1xMNhMCjg" target='_blank' rel="noreferrer">Novelava</a>
      </>,
      details: <>
      A series of AI-Generated Abstract Art Videos put to music c. 2020, home-trained AI using pyspider and StyleGAN2 available on youtube.
      </>,
      youtube:"https://www.youtube.com/embed/tvM5E0CXcOI?controls=0&autoplay=1&mute=1&loop=1",
      img:"",
      img_alt:"A.I. generated abstract art transition video"
    },
    {
      title:<>
      <a href="https://codepen.io/chedaman/full/qemOjq" target='_blank' rel="noreferrer">Wobble Shader</a>
      </>,
      details: <>
      An interactive shader that wobbles an image depending on mouse location.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668754757/EVANMGLOVERCOM/2022-03-31_22-29-35_-_CROPPED_Trim_COMP_rrlv4l.mp4",
      img:"",
      img_alt:"Wobbly Shader Video Demo"
    },
    {
      title:<>
      <a href="https://codepen.io/chedaman/full/wvvrvea" target='_blank' rel="noreferrer">Apple-Style Scroll Video</a>
      </>,
      details: <>
      A reverse engineered frame-by-frame scroll video effect, as seen on Apple's web page e.g. Airpod Pro.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668754852/EVANMGLOVERCOM/2022-03-31_22-27-32_-_CROPPED_Trim_COMP_i6nu1y.mp4",
      img:"",
      img_alt:"Apple Image Scroll Video Demo"
    },
    {
      title:<>
      <a href="https://codepen.io/chedaman/full/BvbmxO" target='_blank' rel="noreferrer">Google-Style 360 Product Viewer</a>
      </>,
      details: <>
      A reverse engineered 360 product viewer, as seen on the google store e.g. Google Home.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668754886/EVANMGLOVERCOM/2022-03-31_22-36-48_-_CROPPED_Trim_COMP_pteona.mp4",
      img:"",
      img_alt:"Google Shop 360 View Video Demo"
    },
    {
      title:<>
      <a href="https://codepen.io/chedaman/full/xoGKoa" target='_blank' rel="noreferrer">Magic Mouse Effect</a>
      </>,
      details: <>
      A webpage overlay that turns an ordinary pointer into a magic wand.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668754914/EVANMGLOVERCOM/2022-03-31_22-31-52_-_CROPPED_COMP_ujl2ux.mov",
      img:"",
      img_alt:"Magic Mouse Video Demo"
    },
    {
      title:<>
      <a href="https://codepen.io/chedaman/full/OOEbWp" target='_blank' rel="noreferrer">Fluid Simulator</a>
      </>,
      details: <>
      A physics enabled fluid simulation.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668755120/EVANMGLOVERCOM/2022-03-31_22-38-34_-_CROPPED_Trim_COMP_unugu0.mp4",
      img:"",
      img_alt:"Fluid Simulator Video Demo"
    },
    {
      title:<>
      <a href="https://codepen.io/chedaman/full/QvoybP" target='_blank' rel="noreferrer">Spider Web Simulator</a>
      </>,
      details: <>
      A physics enabled spider web simulation.
      </>,
      video:"https://res.cloudinary.com/dvcpob8i3/video/upload/v1668755149/EVANMGLOVERCOM/2022-03-31_22-39-19_-_CROPPED_Trim_COMP_dxuiak.mp4",
      img:"",
      img_alt:"Spider Web Simulator Video Demo"
    },
  ];

function Home() {
  return (
    <div className="home-container">
      <ContentRows rows={rowsData}></ContentRows>
    </div>
      );
}

export default Home;
