import React from 'react';
// import {Link} from 'react-router-dom';

function ContentRows({rows}) {
  return (
    rows.map((row, index) => (
      <div key={index} className={index % 2 === 0 ? "home-content content-image-text" : "home-content content-text-image"}>
        {/* On text-image aka odd number index, text goes first */}
        {index % 2 === 1 &&
        <div className="content-text align-left">
          <h3 className="content-text-title">{row.title}</h3>
          <p className="content-text-details">
            {row.details}
          </p>
        </div>
        }
        <div className="content-image">
        {/* show content depending on what is available, video, youtube, or just image */}
        {row.video || row.youtube ?
          row.youtube ? 
            <iframe className="lazyload" data-src={row.youtube} title={row.img_alt} frameBorder="0"></iframe>
            : <div className="video-container" dangerouslySetInnerHTML={{__html:
                `<video width="100%" class="content-video lazyload" loop muted autoplay playsinline preload="metadata" 
                data-src="` + row.video + `" poster="` + row.img + `" alt="` + row.img_alt + `">
                <source data-src="` + row.video + `" type="video/mp4" />
                </video>`
              }}></div>
          : row.img_small && row.img_large ?
            <div className="image-container">
              <picture>
                <source media="screen and (max-width: 799px)"
                        data-srcset={row.img_small}/>
                <source media="screen and (min-width: 800px)"
                        data-srcset={row.img_large}/>
                <img className="lazyload" data-src={row.img} alt={row.img_alt} />
              </picture>
            </div>
            :<img className="lazyload" data-src={row.img} alt={row.img_alt} />
        }
        </div>
        {/* On image-text aka even number index, text goes first */}
        {index % 2 === 0 &&
        <div className="content-text align-left">
          <h3 className="content-text-title">{row.title}</h3>
          <p className="content-text-details">
            {row.details}
          </p>
        </div>
        }
      </div>
    ))
  );
}

export default ContentRows;
