// the background of the website is paint-able
// general js code wrapper for scope maintainance
(function() {
  window.addEventListener('load', (event) => {
    // Initialise an empty canvas and place it on the page
    var canvas = document.createElement("canvas");
    //canvas.classList.add("overlay-canvas");
    canvas.style.cssText = `position:absolute;
      top:0;
      left:0;
      pointer-events: none;
      will-change: transform;
      z-index:0;
      transform: transalte3d(0,0,0);`;
    
    canvas.width = document.getElementById("root").offsetWidth;
    canvas.height = document.getElementById("root").scrollHeight;

    var context = canvas.getContext("2d");
    function resetContext(){
      // make white for now
      context.fillStyle= "rgba(" + 255 + "," + 255 + "," + 255 + "," + 1 + ")";
      context.strokeStyle = "rgba(" + 255 + "," + 255 + "," + 255 + "," + 1 + ")";
      context.lineWidth = 15;
      context.lineCap = "round";
    }
    resetContext();

    // reset canvas size on screen resize
    var resizeTimeout = false;
    // window.resize event listener
    window.onresize = function(){
      // clear the timeout
      clearTimeout(resizeTimeout);
      // start timing for event "completion"
      resizeTimeout = setTimeout(function(){
        // temporarily store canvas image, canvas will clear on attribute change
        var canvasData = context.getImageData(0,0,canvas.width,canvas.height);
        canvas.width = document.getElementById("root").offsetWidth;
        canvas.height = document.getElementById("root").scrollHeight;
        resetContext();
        //repaint canvas image
        context.putImageData(canvasData,0,0);
      }, 250);
    }

    document.body.prepend(canvas);

    var mouseLocation = {x: getRandomInt(0,canvas.width), y: getRandomInt(0,window.innerHeight)};
    var autoMove = true;
    var autoMoveVel = {x: getRandomInt(-5,5), y: getRandomInt(-5,5)}
    var paintLocation = {x: null, y: null};
    // determines whether to paint or not
    var penUp = false;
    var points = [];
    var newStartPoint = true;
    var lastPoint;

    // shim layer with setTimeout fallback
    window.requestAnimFrame = (function(){
      return  window.requestAnimationFrame       ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame    ||
        function( callback ){
        window.setTimeout(callback, 1000 / 60);
      };
    })();
    (function animloop(){
      window.requestAnimFrame(animloop);
      // paint towards mouse, add points
      paintTowardsMouse();
      render();
    })();

    function render(){
      // reset the canvas
      // context.clearRect(0, 0, canvas.width, canvas.height);
      // Draw where the mouse is
      context.beginPath();
      if(lastPoint){
        context.moveTo(lastPoint.x + 0.5, lastPoint.y + 0.5);
      }
      points.forEach(function(point){
        if(point.start){
          context.moveTo(point.x + 0.5, point.y + 0.5);
        }
        context.lineTo(point.x + 0.5, point.y + 0.5);
        lastPoint = point;
        if(points.length > 1){
          points.shift();
        }
      });

      //context.arc(x + 0.5, y + 0.5, 10, 0, Math.PI*2, true); 
      //context.closePath();
      context.stroke();
    }

    function createPoint(x,y,start){
      var point = {
        x: x,
        y: y,
        start: start};
      points.push(point);
    }

    function paintTowardsMouse(){
      if(autoMove){
        var newMouseLocationX = mouseLocation.x + autoMoveVel.x;
        var newMouseLocationY = mouseLocation.y + autoMoveVel.y;
        if((autoMoveVel.x < 0 && newMouseLocationX > 0) || (autoMoveVel.x > 0 && newMouseLocationX < canvas.width)){
          mouseLocation.x = newMouseLocationX;
        }
        if((autoMoveVel.y < 0 && newMouseLocationY > window.scrollY) || (autoMoveVel.y > 0 && newMouseLocationY < window.scrollY + window.innerHeight)){
          mouseLocation.y = newMouseLocationY;
        }
        // 10% chance to change direction
        if(getRandomInt(0,100) < 10){
          autoMoveVel = {x: getRandomInt(-5,5), y: getRandomInt(-5,5)}
        }
      }
      if(!penUp && mouseLocation.x && mouseLocation.y && paintLocation.x && paintLocation.y){
        // there is both a mouse location and a previous paint location
        // trend the paint line towards the mouse location
        paintLocation.x = lerp(paintLocation.x, mouseLocation.x, 0.1);
        if(Math.abs(paintLocation.x - mouseLocation.x) < 0.5){
          paintLocation.x = mouseLocation.x;
        }
        paintLocation.y = lerp(paintLocation.y, mouseLocation.y, 0.1);
        if(Math.abs(paintLocation.y - mouseLocation.y) < 0.5){
          paintLocation.y = mouseLocation.y;
        }
        // create a new start point if necessary
        if(newStartPoint){
          createPoint(paintLocation.x, paintLocation.y, true);
          newStartPoint = false;
        } else {
          // create a new connector point every >1 px.
          if(points.length){
            createPoint(paintLocation.x, paintLocation.y, false);
          }
        }
      } else {
        // paint location has not yet been set
        // set the paint location to the mouse location
        paintLocation.x = mouseLocation.x;
        paintLocation.y = mouseLocation.y;
      }
    }

    // EVENTS
    // on scroll, move the mouse position based on scroll amount
    var prevScrollY = window.scrollY;
    window.onscroll = function(event){
      mouseLocation.y -= prevScrollY - window.scrollY;
      prevScrollY = window.scrollY;
    }

    // note: NO TOUCH CONTROLS
    // stop painting on mousedown
    window.onmousedown = function(event){
      //autoMove = false;
      penUp = true;
    }

    // begin painting again from mouseup
    window.onmouseup = function(event){
      //autoMove = false;
      paintLocation.x = event.pageX;
      paintLocation.y = event.pageY;
      penUp = false;
      newStartPoint = true;
    }

    // update mouse location on mousemove
    window.onmousemove = function(event){
      //autoMove = false;
      mouseLocation.x = event.pageX;
      mouseLocation.y = event.pageY;
    }

    // UTILITIES
    // return a number between start and end while advancing by a certain amount
    function lerp (start, end, amt){
      return (1-amt)*start+amt*end
    }
    // return a random integer between minimum and maximum
    function getRandomInt(minimum, maximum){
      return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    }
  });
})();