import React from 'react';
// import {Link} from 'react-router-dom';

import './Footer.css';

function Footer() {
  return (
    <footer id="main-footer" className="footer">
    <div>
      <span>Evan Glover
      <br/>Creative & Ecommerce Developer
      <br/>New York, NY
      <br/><a href={"mailto:evan@evanmglover.com"}>
        evan@evanmglover.com
      </a></span>
    </div>
      <div><span>&copy;2024 Evan Glover</span></div>
    </footer>
  );
}

export default Footer;
